import Robot24 from '../../../images/robots/robot24.jpg'
import ScrollToTop from '../../../ScrollToTop'

import Footer from '../../../components/Footer'

import './robot.css'

const About = () => {
  return (
    <>
    <ScrollToTop/>
     <section id="robot" className="about__robot">
        <div className="container about__robot-container">
          <div className='robot__section-content'>
            <h1>Rainstik</h1>
            <h4>Crescendo</h4>
              <div>
             
             <h3>Drive Team</h3>
            <p>Driver <b>Abhinav Murthy</b></p>
            <p>Operator <b>Gavin Normand</b></p>
            <p>Drive Coach <b>Tia Bajaj</b></p>
                  </div>
                  <div>
            
            <h3>2024 Statistics</h3>
            <p><b>Overall: </b>
            Team 1807 is ranked <b>61st Worldwide</b>, <b>3rd in Mid-Atlantic</b>, and <b>1st in New Jersey</b></p>
             
              <h5><b>2024 Allentown District Event</b></h5> 
              <p>At this event our team placed <b>5th out of 27</b> teams, and had a record of <b>10-5-1</b>, and was the <b>5th Alliance Captain</b>, making it to the Semi-finals.</p>
              
              <h5><b>2024 Montgomery District Event</b></h5>
              
            <p>Our team placed <b>4th out of 36</b> teams, and had a record of <b>11-5-0</b>, and was the <b>1st pick of Alliance 3</b>, making it to the Semi-finals.</p>
            <h5><b>2024 Mid-Atlantic District Championships</b></h5>
              
              <p>At this event our team placed <b>4th out of 60</b> teams, and had a record of <b>11-4-0</b>, and was the <b>1st pick of Allaince 3</b>, making it to the Semi-finals.</p>
              
              <h5><b>2024 World Championships</b></h5>
              
              <p>Our team placed <b>6th out of 75</b> teams in the Galileo Division, and had a record of <b>10-4-0</b>, and was the <b>6th Allaince Captain</b>, making it to the Semi-finals.</p>
              <h5><b>2024 Awards</b></h5>
              
              <p><b>Exellence in Engineering Award</b> at the 2024 Allentown District Event</p>

                        </div>
         
          </div>  
          <div className="robot__section-image" id="1">
            <img src={Robot24} alt="Summary" />
          </div>
        </div>
      </section>
    <Footer/>
    </>
  )
}

export default About