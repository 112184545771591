import MainHeader from '../../components/MainHeader'
import PagesDirect from '../../components/PagesDirect'
import Footer from '../../components/Footer'
import ScrollToTop from '../../ScrollToTop'

import './home.css'

const Home = () => {
  return (
    <>
      <ScrollToTop/>
      <MainHeader/>
      <PagesDirect/>
      <Footer/>
    </>  
  )
}

export default Home