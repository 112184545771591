import Header from '../../components/Header'
import HeaderImage from '../../images/teampicture.jpg'
import VisionImage from '../../images/teampicture.jpg'
import Footer from '../../components/Footer'
import ScrollToTop from '../../ScrollToTop'

import './events.css'

const Event = () => {
  return (
    <>
    <ScrollToTop/>
    <Header title="Events" image={HeaderImage}>
     Scroll down to read about events our team has hosted or participated in!
    </Header>
    <section className="event__summary">
        <div className="container event__teams-container">
          <div className='event__section-content'>
            <h1>2024 <h3>Allentown District Event</h3></h1>
            <h4>This was our teams first year hosting a district event, and we could not be happier with the outcome.</h4>
            <h4><b>27 teams</b> participated in competing at the event hosted at our very own Allentown High School, and hundreds of spectators filled the stands of our school in support.</h4>
          </div>
          <div className="event__section-image">
            <img src={VisionImage} alt="Summary" />
          </div>
        </div>
      </section>

      {/* <section className="about__story">
      <div className="container about__story-container">
        <div className="about__section-image">
          <img src={VisionImage} alt="Story" />
        </div>
        <div className='event__section-content'>
          <h1>2023 <h3>Charged Up</h3></h1>
        </div>
      </div>
    </section> */}

    <Footer/>
    </>
  )
}

export default Event