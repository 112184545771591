import Header from '../../components/Header'
import HeaderImage from '../../images/teampicture.jpg'
import Footer from '../../components/Footer'
import ScrollToTop from '../../ScrollToTop'

import './sponsors.css'

const Media = () => {
    const sponsorGalleryLength = 11;
    const sponsors = []

    for(let i = 1; i <= sponsorGalleryLength; i++) {
      sponsors.push(require(`../../images/sponsors/${i}.png`))
    }

    return (
        <>
        <ScrollToTop/>
        <Header title="Sponsors" image={HeaderImage}>
            Below are our sponsors who have supported us, and made our teams sucess possible!
        </Header>
        <section className="sponsors">
            <div className="container sponsors__container">
                {
                    sponsors.map((image, index) => {
                        return <article key={index}>
                            <img src={image} alt={`Media Image ${index + 1}`} />
                        </article>
                    })
                }
            </div>
        </section> 
        <Footer/>
        </>
    )
}

export default Media