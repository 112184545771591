import Header from '../../components/Header'
import HeaderImage from '../../images/teampicture2.jpg'
import BehindTheBumper from '../../images/media/behindthebumpers.jpeg'
import RobotReveal from '../../images/media/robotreveal.png'
import ImpactAward from '../../images/media/ImpactAward.png'
import { AiFillInstagram } from "react-icons/ai";
import {GrYoutube} from "react-icons/gr"
import Footer from '../../components/Footer'
import ScrollToTop from '../../ScrollToTop'

import './media.css'
 
const Media = () => {
    const galleryLength = 6;
    const images = []

    for(let i = 1; i <= galleryLength; i++) {
        images.push(require(`../../images/media/${i}.jpg`))
    }

    return (
        <>
        <ScrollToTop/>
        <Header title="Media" image={HeaderImage}>
            Check out some photos that we've gathered over the years at our competitions!
        </Header>

<h1 class="head">Videos</h1>
        <div class="container video__container">
            <div class="video__image">
                <img src={RobotReveal}></img>
                <div class="video__content">
                    <h2>Robot Reveal 2024</h2>
                    <a href="https://www.instagram.com/reel/C4RONPYLoRA/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" rel='noreferrer noopener' target="_blank"><AiFillInstagram/> </a>
                </div>
            </div>
            <div class="video__image">
                <img src={ImpactAward}></img>
                <div class="video__content">
                    <h2>IMPACT Award Video</h2>
                </div>
            </div>
            <div class="video__image">
                <img src={BehindTheBumper}></img>
                <div class="video__content">
                    <h2>Behind The Bumper: 1807</h2>
                    
                    <a href="https://www.youtube.com/watch?v=XXC2n2rT214&ab_channel=FirstUpdatesNow" rel='noreferrer noopener' target="_blank"><GrYoutube/> </a>
                </div>
            </div>
        </div>
<h1 class="head">Photos</h1>
        <section className="media" id="media">
            <div className="container media__container">
                {
                    images.map((image, index) => {
                        return <article key={index}>
                            <img src={image} alt={`Media Image ${index + 1}`} />
                        </article>
                    })
                }
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default Media